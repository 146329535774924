import AccessTimeIcon from '@mui/icons-material/AccessTime'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { CardContent, Typography, Box, Card, Stack, styled } from "@mui/material";
import React from "react";

const cardAddress = (
  <React.Fragment>
    <CardContent>
      <Typography variant="caption" component="div" style={{ textTransform: "uppercase", letterSpacing: '0.1em' }}>
        <LocationOnIcon /><br />
        Adresa
      </Typography>
      <Typography sx={{ mb: 1.5, mt: 1.5 }} color="text.secondary">
        <a href="https://goo.gl/maps/yJXsfj7XghZkF6qq6" target="_blank">Melantrich<br />Václavské náměstí 793/36<br />Praha 1</a>
      </Typography>
      <Typography variant="body2">4. patro, druhý výtah vpravo</Typography>
    </CardContent>
  </React.Fragment>
)

const cardBusinessHours = <React.Fragment>
  <CardContent>
    <Typography variant="caption" component="div" style={{ textTransform: "uppercase", letterSpacing: '0.1em' }}>
      <AccessTimeIcon /><br />
      Provozní doba
    </Typography>
    <Typography sx={{ mt: 1.5 }} color="text.secondary" component={Box}>
      <table width="100%" style={{ maxWidth: "250px", margin: "0 auto" }}><tbody>
        <tr>
          <td style={{ textAlign: "left" }}>Pondělí:</td>
          <td style={{ textAlign: "right" }}>8:00&ndash;20*</td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Úterý:</td>
          <td style={{ textAlign: "right" }}>8:00&ndash;15*</td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Středa:</td>
          <td style={{ textAlign: "right" }}>8:00&ndash;20*</td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Čtvrtek:</td>
          <td style={{ textAlign: "right" }}>8:00&ndash;15*</td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Pátek:</td>
          <td style={{ textAlign: "right" }}>8:00&ndash;20*</td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Sobota:</td>
          <td style={{ textAlign: "right" }}>zavřeno</td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Neděle:</td>
          <td style={{ textAlign: "right" }}>zavřeno</td>
        </tr>
        <tr>
          <td colSpan={2} style={{ textAlign: "left" }}>* Termíny prosím rezervujte telefonicky</td>
        </tr>
      </tbody></table>
    </Typography>
  </CardContent>
</React.Fragment>

const cardContact = <React.Fragment>
  <CardContent>
    <Typography variant="caption" component="div" style={{ textTransform: "uppercase", letterSpacing: '0.1em' }}>
      <LocalPhoneIcon style={{ transform: 'scale(1.0)' }} /><br />
      Kontakt
    </Typography>
    <Typography sx={{ mt: 1.5 }} color="text.secondary" component={Box}>
      <table width="100%" style={{ maxWidth: "250px", margin: "0 auto" }}><tbody>
        <tr>
          <td style={{ textAlign: "left" }}>Tel.</td>
          <td style={{ textAlign: "right" }}><a href="tel:+420-731-809-231">+420 731 809 231</a></td>
        </tr>
      </tbody></table>
    </Typography>
    <Box mt={1}>
      Mgr. Božena Zvolská<br />
      IČO 45738173
    </Box>
  </CardContent>
</React.Fragment>

function OutlinedCard(props: any) {
  return (
    <Box sx={{ minWidth: 245 }} >
      <Card variant="outlined">{props.children}</Card>
    </Box>
  );
}

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function InfoTable() {
  return <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} alignItems="stretch" justifyContent={"center"}>
    <Item><OutlinedCard>{cardAddress}</OutlinedCard></Item>
    <Item><OutlinedCard>{cardBusinessHours}</OutlinedCard></Item>
    <Item><OutlinedCard>{cardContact}</OutlinedCard></Item>
  </Stack>
}

export function InfoBox(props: {}) {
  return <React.Fragment>
    <InfoTable />
    <Box mt={4} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ fontSize: '1.5rem', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} >
        <LocalPhoneIcon style={{ transform: 'scale(1.0)' }} />
        <a href="tel:+420-731-809-231">731 809 231</a>
      </div>
    </Box>
  </React.Fragment>
}
