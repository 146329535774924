import React from "react";

export function Map(props: {}) {
  return <React.Fragment>
    <div className="small text-black-50"><a href="https://goo.gl/maps/yJXsfj7XghZkF6qq6" target="_blank"><b>Melantrich, Václavské náměstí 793/36, Praha 1</b></a></div>
    <div style={{
      overflow: "hidden",
      paddingBottom: "56.25%",
      position: "relative",
      height: 450,
      marginBottom: "1rem",
    }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.284407183887!2d14.426334299999997!3d50.0809618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x79df64ab90c07eb4!2sSalon%20Nora%20Bosner!5e0!3m2!1sen!2scz!4v1657663723180!5m2!1sen!2scz"
        width="600"
        height="450"
        style={{
          border: 0,
          marginBottom: "100px",
        }}
        loading="lazy"
      />
    </div>
  </React.Fragment>
}
